<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.reserve.selectedReserves"
      label="Reservas Pendientes de Procesar"
      :columns="reserve.columns"
      :data="store.state.reserve.reserves"
    />
    <DesktopTable
      v-model:selection="store.state.reserve.selectedReserves"
      label="Reservas Pendientes de Procesar"
      :columns="reserve.columns"
      :data="store.state.reserve.reserves"
    />
    <Actions :items="reserve.actions" />

    <MobileTable
      v-model:selection="store.state.reserve.selectedReservesHist"
      label="Histórico de Reservas"
      :columns="reserve.columnsHist"
      :data="store.state.reserve.reservesHist"
    />
    <DesktopTable
      v-model:selection="store.state.reserve.selectedReservesHist"
      label="Histórico de Reservas"
      :columns="reserve.columnsHist"
      :data="store.state.reserve.reservesHist"
    />
    <Actions :items="reserve.actionsHist" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/reservas/nuevo");
    };

    const changeStatus = async (status) => {
      if (!store.state.reserve.selectedReserves.length && !store.state.reserve.selectedReservesHist.length) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);

        const reserves = [
          ...new Set([
          ...store.state.reserve.selectedReserves.map((item) => item.id),
          ...store.state.reserve.selectedReservesHist.map((item) => item.id),
          ]),
        ];
        const response = await store.dispatch(actions.reserveActions.status, {
          reserves,
          status,
        });
        if (response.ok) {
          await store.dispatch(
            actions.reserveActions.findAll,
            store.state.general.selectedBuilding
          );
          await store.dispatch(
            actions.reserveActions.historic,
            store.state.general.selectedBuilding
          );
          store.commit("setSelectedReserves", []);
          store.commit("setSelectedReservesHist", []);
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };
    const onRemove = async () => {
      if (store.state.reserve.selectedReserves.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.reserveActions.delete,
          store.state.reserve.selectedReserves[0].id
        );
        if (response.ok) {
          store.commit("setSelectedReserves", []);
          await store.dispatch(
            actions.reserveActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const reserve = reactive({
      columns: [
        {
          field: "date",
          header: "Fecha de Reserva",
          sort: true,
        },
        {
          field: "from",
          header: "Desde (hora)",
          sort: true,
        },
        {
          field: "to",
          header: "Hasta (hora)",
          sort: true,
        },
        {
          field: "apartment",
          header: "Apartamento",
          sort: true,
        },
        {
          field: "commonArea",
          header: "Área Común",
          sort: true,
        },
        {
          field: "cost",
          header: "Precio",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
        },
      ],
      columnsHist: [
        {
          field: "date",
          header: "Fecha de Reserva",
          sort: true,
        },
        {
          field: "from",
          header: "Desde (hora)",
          sort: true,
        },
        {
          field: "to",
          header: "Hasta (hora)",
          sort: true,
        },
        {
          field: "apartment",
          header: "Apartamento",
          sort: true,
        },
        {
          field: "commonArea",
          header: "Área Común",
          sort: true,
        },
        {
          field: "cost",
          header: "Precio",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nueva Reserva",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/reservas/nuevo"),
        },
        {
          name: "Confirmar",
          action: async () => await changeStatus(2),
          hidden: true,
        },
        {
          name: "Anular",
          action: async () => await changeStatus(4),
          hidden: true,
        },
        // {
        //   name: "Eliminar",
        //   action: onRemove,
        //   hidden: true,
        // },
      ],
      actionsHist: [
        {
          name: "Anular",
          action: async () => await changeStatus(4),
          hidden: true,
        }
      ],
      
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedReserves", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.reserveActions.findAll,
        store.state.general.selectedBuilding
      );
      await store.dispatch(
        actions.reserveActions.historic,
        store.state.general.selectedBuilding
      );
      await store.dispatch("NOTIFICATION_DELETE", {
        userId: store.state.auth.user.id,
        notificationTypeId: 1,
      });
      store.commit("setLoading", false);
    });

    return { store, reserve };
  },
};
</script>

<style scoped></style>
